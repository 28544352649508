import React from 'react';
import { Link } from 'react-router-dom';

const TermsConditionBang = () => {
  return (
    <div>
      
      <div className="d-flex justify-content-between">
      <h2>শর্ত সমূহ</h2>
        <div>
          <Link to="/terms-condition-english">
            <button id="banglaTransBtn" type="">
              English
            </button>
          </Link>
        </div>
      </div>
      <br/>
      <p> BPP Shop প্ল্যাটফর্ম ("bppshop.com.bd") অ্যাক্সেস বা ব্যবহার করতে, আপনি নিম্নক্ত শর্তাবলী এবং গোপনীয়তা নীতি্মালা সমূহ পালন করতে বাধ্য থাকবেন। </p>
      <p> আপনি যদি এই শর্তাবলীর কোন অংশের সাথে একমত না হন তবে দয়া করে আমাদের প্লাটফর্ম / পরিষেবাগুলি ব্যবহার করবেন না৷ আমাদের প্ল্যাটফর্মের কিছু বৈশিষ্ট্য অ্যাক্সেস করতে, আপনাকে একটি অ্যাকাউন্ট তৈরি করতে হতে পারে। আপনার অ্যাকাউন্টের জন্য প্রদত্ত তথ্য সঠিক কিনা  তা নিশ্চিত করার দায়িত্ব আপনার । </p>
    <p>একটি অ্যাকাউন্ট তৈরি করতে আপনার বয়স কমপক্ষে 18 বছর হতে হবে। </p>
    <p>আমাদের প্লাটফর্মটি  হল একটি মাল্টিভেন্ডর মার্কেটপ্লেস যেখানে বিভিন্ন বিক্রেতা পণ্য এবং পরিষেবা সরবরাহ করে। আমরা বিক্রেতাদের দ্বারা তালিকাভুক্ত আইটেমগুলির গুণগতমান, নির্ভুলতার  গ্যারান্টি দেই না। প্রতিটি বিক্রেতার নিজস্ব শর্তাবলী থাকতে পারে। </p>
    <p>বিক্রেতাদের পণ্য অথবা সেবা নেওয়ার সময় বিক্রেতাদের শর্তাবলী পর্যালোচনা এবং গ্রহণ করা আপনার দায়িত্ব। আপনি প্ল্যাটফর্মটি কোনো বেআইনি বা অসৎ  উদ্দেশ্যে ব্যবহার করতে পারবেন না  । </p>
    <p>আমাদের প্ল্যাটফর্মের বিষয়বস্তু, লোগো, ট্রেডমার্ক এবং অন্যান্য বৈধ সম্পত্তি BPP Shop বা  এর সহযোগীদের মালিকানাধীন। আপনি আমাদের সম্মতি ছাড়া ব্যবহার, পরিবর্তন বা বিতরণ করতে পারবেন না। আপনি কীভাবে প্লাটফর্মটি ব্যবহার করছেন তা  আমাদের গোপনীয়তা নীতিমালা দ্বারা নিয়ন্ত্রিত হয়। </p>
    <p>যা প্রকাশ করে কিভাবে আমরা আপনার ব্যক্তিগত তথ্য সংগ্রহ করি এবং সুরক্ষিত করি। আমাদের প্ল্যাটফর্মে লেনদেন সরাসরি আপনার এবং বিক্রেতাদের মধ্যে পরিচালিত হয়। আমরা প্রদত্ত আইটেম বা পরিষেবার গুণগতমান, নিরাপত্তা বা বৈধতার জন্য দায়ী নই। আমাদের প্লাটফর্মে লেনদেন সম্পর্কিত বিষয়টি পেমেন্ট গেটওয়ের শর্ত সাপেক্ষে করা হয়ে থাকে ( আপনি যদি লেনদেনটি পেমেন্ট গেটওয়ের মাধ্যমে করে থাকেন ) ।</p>
    <p>বিক্রেতাদের সাথে যেকোনো বিরোধ অবশ্যই বিক্রেতার সাথে সরাসরি সমাধান করতে হবে। আমরা বিরোধ নিষ্পত্তির ব্যবস্থা করতে পারি। কিন্তু আমরা বিরোধ সমাধান করতে বাধ্য নই। আপনি যদি এই নিয়ম ও শর্তাবলী লঙ্ঘন করেন বা প্ল্যাটফর্ম বা অন্যান্য ব্যবহারকারীদের জন্য ক্ষতিকারক কার্যকলাপে জড়িত হন তবে বিবেচনার ভিত্তিতে আপনার অ্যাকাউন্ট বন্ধ বা স্থগিত করা হতে পারে। </p>
    <p>BPP Shop এর মাধ্যমে আপনার কোনো ধরনের ক্ষতি সাধন যেমন: ব্যবসায়ে লস , ডাটা লস ইত্যাদি হয়ে থাকলে BPP Shop দায়ী থাকবে না 
আমরা যেকোনো সময় এই শর্তাবলী আপডেট বা সংশোধন করার অধিকার রাখি।  
</p>
    <p>এই নিয়ম ও শর্তাবলী সম্পর্কে আপনার কোন প্রশ্ন বা উদ্বেগ থাকলে, অনুগ্রহ করে আমাদের সাথে +8809610970706 এ যোগাযোগ করুন।</p>
    
    
    
    
    
    </div>
  );
};

export default TermsConditionBang;