import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicyBang = () => {
  return (
    <>
      <div className="privacy_policy_container">
        <div className="d-flex justify-content-between">
          <h3>গোপনীয়তা নীতিমালা:</h3>
          <div>
            <Link to="/privacy-policy-english">
              <button id="banglaTransBtn" type="">
                English
              </button>
            </Link>
          </div>
        </div>
        <br />
        <p>
          BPP Shop, আমরা আপনার গোপনীয়তাকে গুরুত্ব সহকারে নিই এবং আমাদের
          মূল্যবান গ্রাহক এবং ব্যবহারকারীদের ব্যক্তিগত তথ্য সুরক্ষিত রাখতে
          অঙ্গিকারবদ্ধ। এই গোপনীয়তা নীতিমালা ব্যাখ্যা করে যে আপনি যখন আমাদের
          প্ল্যাটফর্ম, bppshop.com.bd ব্যবহার করেন তখন আমরা আপনার তথ্য কীভাবে
          ব্যবহার করে থাকি।
        </p>

        <br />
        <h4>আমরা যেভাবে তথ্য সংগ্রহ করি:</h4>
        <p>
          আপনি যখন আমাদের প্ল্যাটফর্ম ব্যবহার করেন, তখন আমরা বিভিন্ন ধরনের তথ্য
          সংগ্রহ করি, যার মধ্যে রয়েছে:
          <br />
          <br />
          <b>যোগাযোগ সম্পর্কিত তথ্য: </b>
          যোগাযোগ সম্পর্কিত তথ্য যেমন আপনার নাম, ইমেল এড্রেস এবং ফোন নাম্বারের
          মতো প্রয়োজনীয় তথ্যগুলো ।
          <br />
          <b> অ্যাকাউন্ট সম্পর্কিত তথ্য: </b> ব্যবহারকারীর নাম এবং পাসওয়ার্ড।
          <br />
          <b>প্রোফাইল সম্পর্কিত তথ্য:</b> এটি আপনার প্রোফাইল ছবি এবং আপনার
          প্রদান করা অন্যান্য বিষয় গুলো ।
          <br />
          <b>লেনদেন সম্পর্কিত তথ্য:</b> আমাদের প্ল্যাটফর্ম আপনার ক্রয় এবং
          বিক্রয় সম্পর্কে সুনির্দিষ্ট তথ্য সংগ্রহ করে।
          <br /> <b>ব্যবহারকারী দ্বারা প্রদত্ত তথ্য:</b> আপনি আমাদের
          প্ল্যাটফর্মে যে পোস্ট এবং মন্তব্য করেছেন এমন তথ্য।
          <br />
          <b>স্বয়ংক্রিয়ভাবে সংগৃহীত তথ্য:</b> আমাদের প্ল্যাটফর্মের আপনার
          ব্যবহার সম্পর্কিত ডেটা, যেমন আপনার আইপি এড্রেস, ডিভাইসের বিবরণ,
          ব্রাউজারের ধরন। কুকিজ এবং অনুরূপ প্রযুক্তির মাধ্যমে তথ্য সংগ্রহ করি।
          <br />
          <b>তৃতীয় পক্ষের কাছ থেকে প্রদত্ত তথ্য:</b> মাঝে মাঝে, আমরা আমাদের
          পরিষেবার গুণগতমান উন্নত করতে তৃতীয় পক্ষের উত্স থেকে তথ্য নিতে পারি,
          যেমন পরিচয় যাচাইকরণ এবং জালিয়াতি প্রতিরোধ ।
        </p>

        <br />
        <h4>আমরা কিভাবে আপনার তথ্য ব্যবহার করি:</h4>
        <p>
          আমরা বিভিন্ন উদ্দেশ্যে আপনার তথ্য ব্যবহার করি:
          <br />
          <br />
          <b>প্ল্যাটফর্ম কার্যকারিতা:</b> আমাদের প্ল্যাটফর্মের মসৃণ অপারেশন এবং
          উন্নতি নিশ্চিত করণ।
          <br />
          <b>যোগাযোগ:</b> আপনার অ্যাকাউন্ট এবং অর্ডার সংক্রান্ত পরিষেবা সম্পর্কে
          আপনাকে অবহিত রাখা।
          <br />
          <b>বিপণন এবং প্রচার:</b> আমাদের পণ্য, পরিষেবা, প্রচার, এবং অফার
          সম্পর্কে তথ্য প্রদান৷বিঃদ্রঃ ( আপনি যেকোনো সময় অপশন আউট করে রাখতে
          পারবেন ।) আমাদের প্ল্যাটফর্ম কীভাবে ব্যবহার করা হয় তা বোঝা এবং সেই
          অনুযায়ী উন্নতি সাধন করা।
          <br />
          <b>বিশ্লেষণ:</b> আমাদের প্ল্যাটফর্ম কীভাবে ব্যবহার করা হয় তা বোঝা এবং
          সেই অনুযায়ী উন্নতি সাধন করা।
          <br />
          <br />
          আপনার তথ্য যাদের সাথে আমরা শেয়ার করতে পারি:
          <br />
          <br />
          <b>বিক্রেতা:</b> আমাদের প্ল্যাটফর্মে বিক্রেতাদের সাথে লেনদেন এবং
          বিতরণের সুবিধার্থে তথ্য শেয়ার করা হতে পারে ।
          <br />
          <b>আইনি সংক্রান্ত বিষয়াধী:</b> আইনি প্রয়োজনীয়তা মেনে চলার জন্য,
          আমাদের নীতিগুলি প্রয়োগ করতে এবং আমাদের অধিকারগুলোকে রক্ষা করার জন্য
          তথ্য শেয়ার করা হতে পারে।
          <br />
          <b>সর্বজনীনভাবে শেয়ার করা তথ্য:</b> আপনার আমাদের প্ল্যাটফর্মে পোস্ট
          করা তথ্য অন্যান্য ব্যবহারকারীদের কাছে দৃশ্যমান হতে পারে।
        </p>

        <br />
        <h4>যে সকল তথ্যের উপর আপনার নিয়ন্ত্রন রয়েছে:</h4>
        <p>
          আপনি আপনার প্রোফাইল সেটিংসের মাধ্যমে আপনার অ্যাকাউন্টের বিবরণ
          অ্যাক্সেস করতে পারবেন এবং আপডেট করতে পারবেন। আপনি যদি আমাদের রেকর্ড
          থেকে আপনার তথ্য মুছে দিতে চান, তাহলে অনুগ্রহ করে আমাদের সাথে
          support@bppshop.com.bd এ যোগাযোগ করুন।
        </p>

        <br />
        <h4>নীতিমালা সংক্রান্ত আপডেট:</h4>
        <p>
          আমরা এই গোপনীয়তা নীতিমালা সংশোধন এবং পরিবর্তন করতে পারি, আমাদের
          পদ্ধতির পরিবর্তন এবং উন্নয়নের জন্য অথবা আইনি বিবেচনায়।
        </p>
        <br />
        <h4>যোগাযোগ করুন:</h4>
        <p>
          এই গোপনীয়তা নীতিমালা বা আপনার ব্যক্তিগত তথ্য ব্যবহার বিষয়ে আপনার কোন
          প্রশ্ন বা উদ্বেগ থাকলে, অনুগ্রহ করে আমাদের সাথে যোগাযোগ করতে দ্বিধা
          করবেন না। যোগাযোগের মাধ্যম : +8809610970706 support@bppshop.com.bd
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicyBang;
