export const ADD_SHIPPING_ADDRESS_REQUEST = "ADD_SHIPPING_ADDRESS_REQUEST";
export const ADD_SHIPPING_ADDRESS_SUCCESS = "ADD_SHIPPING_ADDRESS_SUCCESS";
export const ADD_SHIPPING_ADDRESS_FAIL = "ADD_SHIPPING_ADDRESS_FAIL";

export const LOAD_SHIPPING_ADDRESS_REQUEST = "LOAD_SHIPPING_ADDRESS_REQUEST";
export const LOAD_SHIPPING_ADDRESS_SUCCESS = "LOAD_SHIPPING_ADDRESS_SUCCESS";
export const LOAD_SHIPPING_ADDRESS_FAIL = "LOAD_SHIPPING_ADDRESS_FAIL";

export const SET_DEFAULT_SHIPPING_ADDRESS_REQUEST = "LOAD_SHIPPING_ADDRESS_REQUEST";
export const SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS = "LOAD_SHIPPING_ADDRESS_SUCCESS";
export const SET_DEFAULT_SHIPPING_ADDRESS_FAIL = "LOAD_SHIPPING_ADDRESS_FAIL";